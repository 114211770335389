import ZGA from '../../pages/products/zenlayer-global-accelerator'
import React from 'react'
import { graphql } from 'gatsby'
export default function Index({ data }) {
  return (
    <div className="gia-product-lp">
      <ZGA
        data={data}
        consoleLinkGen={() =>
          `https://console.zenlayer.com/auth/signup?utm_source=google&utm_medium=cpc&utm_campaign=ZGA&utm_id=ZGA-p&referral=${encodeURIComponent(
            `${location.origin}/${location.pathname}`
          )}`
        }
      />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: {
          elemMatch: { name: { eq: "Zenlayer Global Accelerator" } }
        }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          thumbnail {
            localFile {
              publicURL
            }
          }
          wp_date
          categories {
            name
          }
          created_at
          content
          title
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "gia" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
